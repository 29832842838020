<template>
  <span
    class="badge badge-round badge-info badge-lg"
    style="text-transform: capitalize"
  >
    {{attr.length ?  attr.attribute : "None"}}
  </span>
</template>

<script>
export default {
  props: {
    attr: Object,
  },
};
</script>
